import { fpAddEvent, fpRemoveEvent } from './fpEvents';

declare global {
  interface Window {
    flutter_inappwebview: any;
  }
}

/**
 * This is the main function which hold the channel information and the action on which it run certain functionality.
 * @param channel string Channel name to trigger inside flutter app
 * @param payload object generic object with data of the event triggered
 */
const flutterChannelCall = (channel: string, payload: any): void => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
    console.log(JSON.stringify({ channel, payload }));
  }
  if (window?.flutter_inappwebview) {
    window.flutter_inappwebview
      .callHandler(channel, payload)
      .then((result: any) => {
        console.log(JSON.stringify(result));
      })
      .catch((error: any) => {
        console.log('Flutter Channel call errored', error);
      });
  }
};

const eventAddToBag = (e: CustomEvent): void => {
  const payload = {
    cart_quantity: e.detail?.productsInCart,
  };
  flutterChannelCall('cart', { ...payload, action: 'cart-add' });
};
const eventLoadingStart = (): void => {
  flutterChannelCall('loading', { action: 'loading-start' });
};
const eventLoadingFinish = (): void => {
  flutterChannelCall('loading', { action: 'loading-finish' });
};
const eventLoadingFalse = (): void => {
  flutterChannelCall('loading', [false]);
};
const eventLogIn = (): void => {
  flutterChannelCall('login', { action: 'login-start' });
};
const eventOpenCart = (): void => {
  flutterChannelCall('cart', { action: 'cart-open' });
};
const eventOpenSellerPage = (): void => {
  flutterChannelCall('seller', { action: 'seller-open' });
};
const eventOpenLocationPage = (): void => {
  flutterChannelCall('seller', {
    action: 'location-open',
  });
};
const eventOpenSellerURL = (e: CustomEvent): void => {
  flutterChannelCall('seller', {
    action: 'seller-open-url',
    url: e.detail.url,
  });
};
const eventOpenAccountURL = (e: CustomEvent): void => {
  flutterChannelCall('openmyaccounturl', {
    action: 'open-myaccount-url',
    url: e.detail.url,
  });
};
const eventOpenURL = (e: CustomEvent): void => {
  flutterChannelCall('openurl', {
    action: 'external-open-url',
    url: e.detail.url,
  });
};
const eventOpenShopURL = (e: CustomEvent): void => {
  flutterChannelCall('openshopurl', {
    action: 'open-shop-url',
    url: e.detail.url,
  });
};
const eventAnalyticsTrackEvent = (e: any): void => {
  flutterChannelCall('analytics', {
    action: 'track',
    ...e.detail,
  });
};
const eventAnalyticsPageViewEvent = (e: any): void => {
  flutterChannelCall('analytics', {
    action: 'page-view',
    event: 'Page Viewed',
    ...e.detail,
  });
};
const eventShareContent = (e: CustomEvent): void => {
  const payload = {
    url: e.detail?.url,
    title: e.detail?.title,
  };
  flutterChannelCall('share', { ...payload, action: 'share-url' });
};
const eventSearchEnd = (): void => {
  flutterChannelCall('search', { action: 'search-end' });
};

export const mobileAppMountEvents = (): void => {
  fpAddEvent('fpAddToCart', eventAddToBag);
  fpAddEvent('fpLoadingStart', eventLoadingStart);
  fpAddEvent('fpLoadingFinish', eventLoadingFinish);
  fpAddEvent('fpLoadingFalse', eventLoadingFalse);
  fpAddEvent('fpLogin', eventLogIn);
  fpAddEvent('fpOpenCart', eventOpenCart);
  fpAddEvent('fpOpenSellerPage', eventOpenSellerPage);
  fpAddEvent('fpOpenSellerURL', eventOpenSellerURL);
  fpAddEvent('fpOpenSellerLocation', eventOpenLocationPage);
  fpAddEvent('fpOpenAccountURL', eventOpenAccountURL);
  fpAddEvent('fpOpenURL', eventOpenURL);
  fpAddEvent('fpOpenShopURL', eventOpenShopURL);
  fpAddEvent('fpAnalyticsTrackEvent', eventAnalyticsTrackEvent);
  fpAddEvent('fpAnalyticsPageViewEvent', eventAnalyticsPageViewEvent);
  fpAddEvent('fpShareContent', eventShareContent);
  fpAddEvent('fpSearchEnd', eventSearchEnd);
};

export const mobileAppDismountEvents = (): void => {
  fpRemoveEvent('fpAddToCart', eventAddToBag);
  fpRemoveEvent('fpLoadingStart', eventLoadingStart);
  fpRemoveEvent('fpLoadingFinish', eventLoadingFinish);
  fpRemoveEvent('fpLoadingFalse', eventLoadingFalse);
  fpRemoveEvent('fpLogin', eventLogIn);
  fpRemoveEvent('fpOpenCart', eventOpenCart);
  fpRemoveEvent('fpOpenSellerPage', eventOpenSellerPage);
  fpRemoveEvent('fpOpenSellerURL', eventOpenSellerURL);
  fpRemoveEvent('fpOpenSellerLocation', eventOpenLocationPage);
  fpRemoveEvent('fpOpenAccountURL', eventOpenAccountURL);
  fpRemoveEvent('fpOpenURL', eventOpenURL);
  fpRemoveEvent('fpOpenShopURL', eventOpenShopURL);
  fpRemoveEvent('fpAnalyticsTrackEvent', eventAnalyticsTrackEvent);
  fpRemoveEvent(
    'fpAnalyticsPageViewEvent',
    eventAnalyticsPageViewEvent,
  );
  fpRemoveEvent('fpShareContent', eventShareContent);
  fpRemoveEvent('fpSearchEnd', eventSearchEnd);
};
