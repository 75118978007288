import * as Sentry from '@sentry/nextjs';

export const base: {
  release: string;
  dsn: string | undefined;
  enabled: boolean;
  environment: string | undefined;
  tracesSampleRate: number;
  ignoreErrors: (string | RegExp)[];
} = {
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: `shop-app@${process.env.NEXT_PUBLIC_SHOPAPP_VERSION}`,
  dsn: process?.env.NEXT_PUBLIC_SENTRY_DSN || '',
  enabled: process.env.ENVIRONMENT !== 'local',
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
  ignoreErrors: ['node_modules', /Network Error/i],
};

export const beforeBreadcrumbFilter = (obj: any): any => {
  const filters = ['clarity', 'datadoghq', 'analytics'];
  return filters.some((val) => obj?.message.includes(val))
    ? null
    : obj;
};

const ignoredErrors = [
  'No currency found',
  'embedded_svc.utils.isCommunityOrSite',
  'Load failed' // ios fetch errors - https://github.com/vercel/next.js/issues/46635
];
export const sentryBeforeSend = (
  event: Sentry.Event,
  hint: Sentry.EventHint,
): Sentry.Event | null => {
  const error = hint?.originalException as Error | null;
  if (error) {
    for (const ignoredError of ignoredErrors) {
      if (error?.message?.includes(ignoredError)) {
        return null;
      }
    }
  }
  return event;
};
